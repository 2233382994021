export default {
    name: 'window_module',
    state: {
        windowWidth: window.innerWidth
    },
    getters: {
        getWindowWidth:(state)=>state.windowWidth
    },
    mutations: {
        setWindowWidth: (state) => {
            state.windowWidth = window.innerWidth
        }
    },
}
