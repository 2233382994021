import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";
import {Device} from "@/services/Device";

export default {
    name: 'user',
    state: {
        permissions: {},
        selectUserInfo: null,
        role_permissions: [],
        current_user: null,
        current_session: null,
        employeeFine: [],
        allUsers: [],
        userTypes: ['Super_User', 'Developer', 'Technician', 'Sales'],
        attemptedLoginRoute : { name : `user-dashboard` },
        otp: null,
        otpVerified: false,
    },
    getters: {
        getPermissions: (state) => state.permissions,
        getSelectUser: (state) => state.selectUserInfo,
        getRoles: (state) => state.role_permissions,
        getEmployeeFine: (state) => state.employeeFine,
        getCurrentUser: (state) => state.current_user,
        getAllUsers: (state) => state.allUsers,
        getUserTypes: (state) => state.userTypes,
        getAttemptedRoute: (state) => state.attemptedLoginRoute,
        getUserByID: (state) => (id)=> state.allUsers.find(user => user.id === id),
        getOtp: (state) => state.otp,
        isOtpVerified: (state) => state.otpVerified,
    },
    mutations: {
        setOtpVerified: (state, verified) => {
            state.otpVerified = verified;
        },
        setOtp: (state, otp)=>{
          state.otp = otp;
        },
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions; // Save the entire permissions object
        },
        CLEAR_PERMISSIONS(state) {
            state.permissions = {}; // Clear permissions on logout
        },
        SET_EMPLOYEE_FINE: (state, data) => {
            state.employeeFine = data
        },
        setPermissionRoles: (state, data) => {
            state.role_permissions = data
        },

        setCurrentUser: async (state, data) => {
            state.current_user = data
        },

        setUsers: async (state, data) => {
            if (data) await Storage.setUsers(data)
            else data = await Storage.getAllUsers();
            state.allUsers = data;
        },
        setUserTypes: (state, data) => state.userTypes = data,
        setAttemptedRoute: (state, data) => state.attemptedLoginRoute = data,
        setSessionID:async (state , data) => {
            if (data) await Storage.setSessionID(data)
            else data = await Storage.getSessionID();
            state.current_session = data;
        },
        setUserInfo(state, user) {
            state.selectUserInfo = user; // Update user state
        },
    },
    actions: {

        initializeAuth(context) {
            const otpVerified = localStorage.getItem('otpVerified') === 'true';
            context.commit('setOtpVerified', otpVerified);
            // Other initialization logic
        },
        setPermissions({ commit }, permissions) {
            commit("SET_PERMISSIONS", permissions);
        },
        clearPermissions({ commit }) {
            commit("CLEAR_PERMISSIONS");
        },
        updateSetUserInfo({ commit }, user) {
            commit("setUserInfo", user);
        },
        async fetchEmployeeFine( context, userId = null ) {
            try {
                let users = context.getters.getCurrentUser;
                let idToUse = userId || users.id;
                const response = await axios.get(synergy4 + `disciplinaryAction/getEmployeeFine/${idToUse}`);
                context.commit("SET_EMPLOYEE_FINE", response.data); // Commit mutation to update state
                console.log(response.data);
            } catch(error) {
                await errorHandler.tomcatError(error);
            }
        },
        async getAllRoles( context ) {
            try {
                const response = await axios.get(`${synergy4}employee/getAllRoles`);
                context.commit("setPermissionRoles", response.data);
                this.allRoles = response.data;
                console.log(this.allRoles);
            } catch (error) {
                await errorHandler.tomcatError(error);
            }
        },

        getEarlyLeaveRequest: async (context,userID) => {
            return await axios.get(`${synergy4}employee/getEarlyLeaveRequest/${userID}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        triggerResetPassword: (context,payload) => {
            return context.dispatch(`sendRequest`,{
                url : `${window.synergy4}employee/resetPassword`,
                payload:{
                    email: payload,
                }
            })
        },
        resetPassword: (context,payload) => {
            return context.dispatch(`sendRequest`,{
                url : `${window.synergy3}employee/processResetPassword`,
                payload,
                method: "post"
            })
        },

        updateUserOnlineStatus:async (context) => {
            let user = await Storage.getCurrentUser();
            let id = user.id;
            return axios.put(ticketApi + `user/updateOnlineStatus/${id}`,{})
        },
        getAllUsers: async (context) => {
            return axios.get(ticketApi + `user/getAll`)
                .then(({data}) => {
                    context.commit('setUsers', data)
                    console.log(data)
                });
        },
         userLogin: async (context, payload) => {
             return axios.post(ticketApi + `user/Login`, payload)
                 .then(({data}) => {
                     context.commit('setCurrentUser', data)
                     return router.push({name: 'OTP'});
                 }).catch(err => errorHandler.tomcatError(err))
         },
        userLogin2: async (context, payload) => {
            return axios.post(ticketApi + `user/Login`, payload)
                .then(({data}) => {
                    return context.commit('setCurrentUser', data);
                }).catch(err => errorHandler.tomcatError(err))
        },

        verifyOtp: (context, payload) => {
            console.log(payload)
            context.commit('setOtp', payload.otp)
            return axios.post(ticketApi + 'user/verifyOtp', payload)
                .then(({data}) => {
                    context.commit(`setSessionID`,data)
                    localStorage.setItem('setSessionID', data);
                    context.commit('setOtpVerified', true); // Set OTP as verified
                    localStorage.setItem('otpVerified', 'true');
                    let route = context.getters.getAttemptedRoute || { name: 'user-dashboard' };
                    let routeName = route.name;
                    const resolvedRoute = router.resolve({ name: routeName });
                    let exists = resolvedRoute.route.name === routeName;
                    if(!exists) route = { name : `user-dashboard` };
                    return router.push(route);
                }).catch(err => errorHandler.tomcatError(err));
        },
        searchTurnoverData: (context, user) => {
            const dateRange = context.getters.getGlobalDateRange;
            let obj = {
                quotedBy: user,
                ...context.getters.getGlobalDateRange
            }//
            return axios.get(synergy4+`api/sales/turnover?quotedBy=${user}&from=${dateRange.from}&to=${dateRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        searchQuoteTurnoverData: (context, user) => {
            const dateRange = context.getters.getGlobalDateRange;
            let obj = {
                user,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(synergy4+`api/sales/quoteTurnover?user=${user}&from=${dateRange.from}&to=${dateRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        searchHardwarePercentageData: (context, user) => {
            const dateRange = context.getters.getGlobalDateRange;
            let obj = {
                quotedBy: user,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(synergy4+`api/sales/hardwarePercentage?quotedBy=${user}&from=${dateRange.from}&to=${dateRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        searchPercentageToTargetData: (context, user) => {
            const dateRange = context.getters.getGlobalDateRange;
            let obj = {
                quotedBy: user,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(synergy4+`api/sales/percentageToTarget?quotedBy=${user}&from=${dateRange.from}&to=${dateRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        searchConversionPercentageData: (context, user) => {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4+`api/sales/conversionPercentage?username=${user}&from=${dataRange.from}&to=${dataRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        searchCommsionData: (context, user) => {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4+`api/sales/calculate/${user}?from=${dataRange.from}&to=${dataRange.to}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getAllSalesMeetings2: (context, user) => {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4+`api/sales/attended/count?From=${dataRange.from}&to=${dataRange.to}&userName=${user}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getAllLateToMeetings2: (context, user) => {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4+`api/sales/late/count?From=${dataRange.from}&to=${dataRange.to}&userName=${user}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        searchAllQuotes: async (context, payload) => {
            let storeDB = 'burntofi_chrilan';
            let obj = {
                ...context.getters.getGlobalDateRange,
                ...payload,
                // User: !payload.User ? user.Username : payload.User,
            };
            console.log('THIS IS THE OBJECT', obj)
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/searchAllQuotes`, obj)
                .then(({data}) => {
                    console.log("THIS DATA", data);
                    return data;
                })
                .catch((err) => errorHandler.tomcatError(err))
        },
        // async getAllInvoices(context, payload) {
        //     let store = 'burntofi_chrilan';
        //     payload = {
        //         ...context.getters.getGlobalDateRange,
        //         ...payload
        //     }
        //     return axios.post(backOfficeTomcatApi + `other/${store}/searchSales`, payload)
        //         .then(({data}) => data)
        //         .catch((err) => errorHandler.tomcatError(err))
        // },
        async getAllInvoices(context, user) {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4 + `api/sales/GetInvoicesByUsername?username=${user}&from=${dataRange.from}&to=${dataRange.to}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getMeetingPunctuality(context, user) {
            let dataRange = context.getters.getGlobalDateRange;
            return axios.get(synergy4 + `api/sales/SalesMeetings?startDate=${dataRange.from}&endDate=${dataRange.to}&userName=${user}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        createUser: (context, payload) => {
            let phoneNumber = payload.phone_number;
            payload.phone_number = `260`+phoneNumber;
            return axios.post(ticketApi + `user`, payload)
                .then(() => context.dispatch('getAllUsers'))
        },

        updateUser: (context, payload) => {
            let ID = payload.id;
            let phoneNumber = payload.phone_number;
            payload.phone_number = `260`+phoneNumber
            return axios.put(ticketApi + `user/${ID}`, payload)
                .then(() => context.dispatch('getAllUsers'))
        },

        getUserByID: (context, id) => {
            return axios.get(ticketApi + `user/${id}`)
                .then(({data}) => data)
        },
    }
}
