<template>
  <div class="container-fluid navigation-bar p-1 px-2">
    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>
    <div class="navbar-logo"></div>

    <div class="navigation-box">
      <button class="burger mx-2" @click="showTicketPopup" v-if="getSupportTicketCount > 0">
        <span class="notification-badge bg-warning">{{ getSupportTicketCount }} Tickets</span>
        <font-awesome-icon icon="bell"/>
      </button>
    </div>

    <custom-modal ref="supportTickets" title="Pending Support Tickets" size="lg">
      <ul class="list-unstyled">
        <li v-for="ticket in getSupportTickets" :key="ticket.id+`-mobile`" class="card mb-2">
          <div class="card-header d-flex flex-row justify-content-between">
            Ticket #{{ ticket.id }}
            <div class="d-flex justify-content-end">
              <a class="btn btn-sm mx-3 btn-outline-info" @click="viewTicket(ticket.id)">View</a>
              <a class="btn btn-sm btn-primary" @click="handleClaimTicket(ticket.id)">Claim</a>
            </div>
          </div>
          <div class="card-body d-flex flex-column">
            <span>{{ ticket.title }}</span>
            <span>{{ ticket.client ? ticket.client : 'No Client Specified' }}</span>
            {{ ticket.store ? ticket.store : 'No Store Specified' }}
            <span>Created : {{ $utils.displayDate(ticket.createdDate) }}</span>
            <span>Due Date : {{ $utils.displayDate(ticket.dueDate) }}</span>
            <div class="d-flex flex-row justify-content-evenly">

            </div>
          </div>
        </li>
      </ul>
    </custom-modal>
    <audio ref="dingSound" src="@/assets/notification.mp3"></audio>
    <div class="user-settings my-2">

<!--      <div class="fine-div" v-if="showFine" @click="goToFine">-->
<!--        <p>Fined!</p>-->
<!--        <img src="@/assets/img/debt.png" alt="tax man" class="flashing-image" />-->
<!--      </div>-->

      <div class="button-container" @click="viewUser">
        <button class="buttonn">
          <svg
              class="icon"
              stroke="currentColor"
              fill="black"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12 2.5a5.5 5.5 0 0 1 3.096 10.047 9.005 9.005 0 0 1 5.9 8.181.75.75 0 1 1-1.499.044 7.5 7.5 0 0 0-14.993 0 .75.75 0 0 1-1.5-.045 9.005 9.005 0 0 1 5.9-8.18A5.5 5.5 0 0 1 12 2.5ZM8 8a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z"
            ></path>
          </svg>
        </button>

        <p> {{ curUser.Username }}</p>
        <font-awesome-icon icon="fa-circle-exclamation" class="flashing" v-if="showFine"/>
      </div>



      <!--      <button class="button">-->
<!--        <p>Fine pending</p>-->
<!--        <div class="active_line"></div>-->
<!--        <span class="text">You owe K4000</span>-->
<!--      </button>-->

      <button @click="findTicket" class="burger mx-3">
        <font-awesome-icon icon="magnifying-glass"/>
      </button>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate"/>
      </button>
    </div>

    <custom-modal ref="ticketFinder" title="Ticket Finder" :is-form="true" @submit="checkTicket">
      <p class="text-primary text-sm">NB: Type a ticket number to quickly open it</p>

      <label for="checkTicketNumber" class="fw-bold">Ticket #</label>
      <input type="number" class="form-control" v-model="ticketNumber" id="checkTicketNumber" ref="tickNumber">
    </custom-modal>
  </div>
</template>
<script>
import CustomModal from "@/components/layout/CustomModal";
import Swal from "sweetalert2";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {errorHandler} from "@/services/errorHandler";

export default {
  name: "NavigationBar",
  components: {CustomModal},
  data() {
    return {
      checkObject: false,
      showFine: false,
      previousTicketCount: 0,
      ticketNumber: ``,
      onlineStatusInterval: null,
      ticketCountInterval: null,
    }
  },
  props: {
    toggle: {
      type: Function,
      require: true,
    },
  },
  computed: {
    ...mapGetters(["getEmployeeFine"]),
    ...mapGetters(["getSupportTicketCount"]),
    ...mapGetters(["getSupportTickets"]),
    curUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  mounted() {
    // Connect to the WebSocket server
    // this.connectWebSocket();
  },
  methods: {
    viewUser() {
      this.$store.commit("setUserInfo", this.curUser);
      console.log(this.curUser)
      this.$router.push({
        name: "view-user",
        params: { id: this.curUser.id },
      });
    },
    async fetchFineData() {
      try {
        const response = await axios.get(synergy4 + `disciplinaryAction/getEmployeeFine/${this.curUser.id}`);
        this.rows = response.data;
        console.log(this.rows);
      } catch (error) {
        await errorHandler.tomcatError(error);
      }
    },
    showFines() {
      if (Array.isArray(this.rows)) {
        this.showFine = this.rows.some(fine => fine.isPaid === false);
      } else {
        this.showFine = false;
      }
    },
    goToFine() {
      this.$router.push({ name: "fine"});
    },
    ...mapActions(["fetchEmployeeFine"]),
    syncData() {
      window.location.reload(true);
    },
    async showTicketPopup() {
      if (this.getSupportTicketCount > 0) {
        await this.$store.dispatch(`getAllSupportTickets`);
        this.$refs.supportTickets.openModal();
      } else {
        Swal.fire({
          icon: 'info',
          title: 'No pending tickets available!',
          timer: 2000,
          showConfirmButton: false
        });
      }
    },

    ...mapActions(["connectWebSocket"]),
    // showTicketPopup() {
    //   if (this.getSupportTicketCount > 0) {
    //     this.$refs.supportTickets.openModal();
    //   } else {
    //     Swal.fire({
    //       icon: "info",
    //       title: "No pending tickets available!",
    //       timer: 2000,
    //       showConfirmButton: false,
    //     });
    //   }
    // },

    async handleClaimTicket(ticketId) {
      return this.claimTicket(ticketId).then(() => {
        return Swal.fire({
          icon: 'success',
          title: 'Claimed ticket successfully!',
          timer: 2000,
          showConfirmButton: false
        }).then(() => this.fetchAvailableTickets());
      });
    },
    viewTicket(ticketId) {
      if (this.$route.name === 'view-ticket') {
        return this.$router.replace({ name: 'view-ticket', params: { ticketId } })
            .then(()=>window.location.reload(true));
      }
      return this.$router.push({name: 'view-ticket', params: {ticketId}})
    },

    findTicket() {
      this.$refs.ticketFinder.openModal();
      setTimeout(() => {
        document.getElementById('checkTicketNumber').focus();
      }, 500);
    },
    checkTicket() {
      this.$utils.showLoading();
      return this.$store.dispatch(`checkTicket`, this.ticketNumber)
          .then(async (res) => {
            if (res) {
              this.viewTicket(this.ticketNumber)
              this.ticketNumber = ``
              return;
            }
            await responses.showWarning(`Ticket ${this.ticketNumber} not found`);
            return this.findTicket();
          }).finally(() => this.$utils.hideLoading());
    },
    updateUserOnlineStatus() {
      return this.$store.dispatch(`updateUserOnlineStatus`)
    },
    async fetchAvailableTickets() {
      this.$utils.showLoading();
      let currentCount = this.$store.getters.getSupportTicketCount;
      return this.$store.dispatch(`getAllSupportTickets`)
          .then(()=>{
            let newCount = this.$store.getters.getSupportTicketCount;
            if (currentCount < newCount) this.playDingSound();
          }).finally(()=>this.$utils.hideLoading());
    },
    async claimTicket(ticketNumber) {
      try {
        let user = this.$store.getters.getCurrentUser;
        const userID = user.id;
        await axios.put(ticketApiV2 + 'ticket/claimTicket', {
          userID,
          ticketNumber
        }).then(() => {
          this.fetchAvailableTickets();
          this.$store.dispatch(`getPreLoginData`)
        });
      } catch (error) {
        return errorHandler.tomcatError(error)
      }
    },

    playDingSound() {
      this.$refs.dingSound.play();
    },
    startOnlineStatusUpdate() {
      this.onlineStatusInterval = setInterval(async () => {
        await this.updateUserOnlineStatus();
      }, 60000 * 10); // 60000ms = 1 minute * 30 = 30 minute
    },
    startSupportUpdate() {
      this.ticketCountInterval = setInterval(async () => {
        await this.$store.dispatch(`countSupportTickets`);
      }, 30000); // 60000ms = 1 minute * 30 = 30 minute
    },
  },
  watch:{
    getSupportTicketCount(newCount,currentCount){
      if (currentCount < newCount) this.playDingSound();
    },
  },
  beforeDestroy() {
    clearInterval(this.onlineStatusInterval);
    clearInterval(this.ticketCountInterval);
  },
  async created() {
    await this.fetchFineData();
    this.showFines();
    await this.updateUserOnlineStatus();
    this.startOnlineStatusUpdate();
    this.startSupportUpdate();
  }
};
</script>
<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

button.btn {
  margin: 5px;
}

.red-blinking {
  color: red;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.notification-badge {
  position: fixed;
  margin-right: 5px;
  color: white;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  font-size: 12px;
  opacity: 0; /* Initially hidden */
  transform: scale(0); /* Initially small size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.burger .notification-badge {
  opacity: 1;
  transform: scale(1);
  animation: notification-pulse 1.5s infinite;
}

@keyframes notification-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
}

//@import "src/assets/style/hide-table-mobile";
.text-sm {
  font-size: 12px;
}

@keyframes blinkingRed {
  0%, 100% { background-color: red; color: white; }
  50% { background-color: white; color: black; border: 2px solid black; }
}
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  border: 2px solid red;
  cursor: pointer;
  border-radius: 0.4em;
  background: red;
  color: white;
  font-weight: bold;
  animation: blinkingRed 2s infinite;
}

.active_line {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0.9em;
  height: 0.4em;
  background-color: #999;
  border: none;
  border-radius: 1em;
  transition: all 0.15s linear;
}

.button:active .active_line,
.button:focus .active_line {
  width: 2.3em;
  background-color: rgb(41, 126, 255);
}


@keyframes wow {
  20% {
    scale: 0.8;
  }

  30% {
    scale: 1;
    transform: translateY(0);
  }

  50% {
    transform: translateY(-6px);
  }

  65% {
    transform: translateY(4px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    scale: 1;
  }
}

.text {
  z-index: 1000;
  content: "";
  position: absolute;
  top: 4.5em;
  width: fit-content;
  height: 2.6em;
  background-color: #666;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  text-shadow: 0 0 10px rgb(0, 0, 0);
  opacity: 0;
  transition: all 0.25s linear;
}

.button:hover .text {
  opacity: 1;
}

@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.flashing-image {
  width: 30px;
  height: 30px;
  animation: flash 1s infinite; /* Flashes every 1 second */
}
.flashing {
  animation: flash 1s infinite;
}
.fine-div {
  width: 90px;
  padding: 5px;
  display: flex;

  border-radius: 10px;
  background-color: yellow;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.tooltip-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.fine-div:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.button-container {
  display: flex;
  background-color: aliceblue;
  width: 150px;
  height: 40px;
  margin-left: 8px;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  cursor: pointer;
}
.button-container:hover {
  background-color: lightgray;
}

.buttonn {
  outline: 0 !important;
  border: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.buttonn:hover {
  transform: translateY(-3px);
}

.icon {
  font-size: 20px;
}

</style>
