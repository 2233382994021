<template>
  <div class="page">
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12">
          <div class="card text-center">
            <div class="card-body">
              <img src="@/assets/img/success.png" alt="Success" class="img-fluid mb-4" style="max-width: 150px;">
              <h2 class="card-title mb-3">Payment Success!</h2>
              <p class="card-text mb-4">Thank you for your purchase.</p>
<!--              <div v-if="processedSales.length > 0" class="mt-3">-->
<!--                <p class="card-text">Processed Sales:</p>-->
<!--                <ul class="list-group list-group-flush">-->
<!--                  <li v-for="(sale, index) in processedSales" :key="index" class="list-group-item">-->
<!--                    Sale #{{ sale }} - Successfully recorded-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestSuccessScreen",
  data() {
    return {
      processedSales: []
    }
  },
  beforeMount() {
    this.recordTransaction();
  },
  methods: {
    async recordTransaction() {
      try {
        let parsedReference = JSON.parse(window.atob(this.$route.query.ClientRef));
        console.log("Parsed reference:", parsedReference);

        let saleNumbers = [];
        if (parsedReference.quoteNumber) {
          try {
            saleNumbers = JSON.parse(window.atob(parsedReference.quoteNumber));
            console.log("Sale numbers extracted:", saleNumbers);
          } catch (error) {
            console.error("Error parsing sale numbers:", error);
            saleNumbers = [parsedReference.quoteNumber];
          }
        }

        this.$store.commit('setLoadingState', true);

        console.log(saleNumbers)
        if (Array.isArray(saleNumbers) && saleNumbers.length > 1) {
          for (const saleNum of saleNumbers) {
            const payload = {
              accountNumber: this.$route.query.Cell,
              quoteNumber: saleNum.saleNum,
              amount: saleNum.amount,
              discountAmount: parsedReference.discountAmount || '0',
            };
            try {
              await this.$store.dispatch('receiveIntegratedPayment', payload);
              this.processedSales.push(saleNum);
            } catch (error) {
              console.error(`Error processing sale number ${saleNum}:`, error);
            }
          }
        } else {
          console.log(`parsedReference`, parsedReference)
          const payload = {
            accountNumber: this.$route.query.Cell,
            quoteNumber: saleNumbers[0].saleNum,
            amount: parsedReference.amount,
            discountAmount: parsedReference.discountAmount || '0',
          };

          await this.$store.dispatch('receiveIntegratedPayment', payload);
        }
      } catch (error) {
        console.error("Error in recordTransaction:", error);
        await errorHandler.tomcatError(error);
      } finally {
        this.$store.commit('setLoadingState', false);
      }
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.list-group-item {
  text-align: left;
  border-left: none;
  border-right: none;
}
</style>