<template>
  <div class="card shadow-lg rounded-3 py-3" style="border-radius:0; border:none">
    <div class="card-body p-1">
      <h1 class="card-title mb-3 text-dark " style="font-weight:700"><span
          style="color: #4169e1">Help Desk</span> Login</h1>
      <form class="form" @submit.prevent="login">
        <div class="flex-column">
          <label>Username </label></div>
        <div class="inputForm">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          <input placeholder="Enter your Username" class="input" type="text" v-model="form.Username">
        </div>

        <div class="flex-column">
          <label>Password </label></div>
        <div class="inputForm">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="-64 0 512 512" height="20">
            <path
                d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"></path>
            <path
                d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"></path>
          </svg>
          <input placeholder="Enter your Password" class="input" :type="passwordType" v-model="form.password">
        </div>

        <div class="flex-row">
          <div>
            <div class="form-check form-switch my-1">
              <input class="form-check-input" type="checkbox" role="switch" id="showPassword" v-model="show_password">
              <label class="form-check-label" for="showPassword">Show Password</label>
            </div>
          </div>
          <span class="span" @click="reset">Forgot password?</span>
        </div>
        <div class="text-center">
          <p class="text-danger">{{ warning }}</p>
          <button :disabled="loading" class="login-btn primary" type="submit">
            Login
            <span v-if="loading" aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'LoginForm',
  beforeMount() {
    this.checkOnline();
  },
  computed: {
    passwordType() {
      return this.show_password ? 'text' : 'password';
    }
  },
  data() {
    return {
      loading: false,
      show_password: false,
      form: {
        Username: "",
        password: ""
      },
      warning: ''
    }
  },
  methods: {
    reset() {
      return Swal.fire({
        title: "Enter your email",
        input: "email",
        icon: "icon",
        showCancelButton: true,
        background: '#fff',
        color: '#000',
        confirmButtonText: "Reset Password",
        showLoaderOnConfirm: true,
        preConfirm: async (input) => {
          return this.$store.dispatch('triggerResetPassword', input)
              .then(() => {
                this.$router.push('/ResetView');
                responses.showSuccess('Reset Token has been sent to your Email')
              })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    },
    checkOnline() {
      return this.$nextTick(() => {
        window.setInterval(() => {

          if (this.warning === `Internet Disconnected!!`) {
            this.warning = ``;
          }

          if (!this.warning && !navigator.onLine) {
            this.warning = `Internet Disconnected!!`;
          }

        }, 200);
      })
    },
    login() {
      this.loading = true;
      localStorage.setItem("otp", JSON.stringify(this.form));
      return this.$store.dispatch('userLogin', this.form)
          .catch((err) => this.warning = err)
          .finally(() => this.loading = false);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card {
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  max-width: 95vw;
  margin-top: -3rem !important;
}

.login-btn {
  color: white;
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 7px 18px rgba(11, 102, 255, 0.3) !important;

}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 5px;
  width: 450px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width: 767px) {
  .card {
    padding-left: 10px;
    padding-right: 10px;
    width: 95%;

    margin-top: -3rem !important;
  }
  .form {
    width: 100%;
  }
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form button {
  align-self: flex-end;
}

.flex-column > label {
  color: #151717;
  font-weight: 600;
}

.inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-color: #0b66ff;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  border: 1px solid #2d79f3;;
}


</style>
