<template>
  <div class="drawer">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
      </div>
    </div>
    <div class="accordion menu" id="accordionPanelsStayOpenExample">
      <div class="accordion-item" v-if="isUpdateAvailable">
        <a @click="updateApp">
          <font-awesome-icon class="icon text-info" icon="Ffa-solid fa-exclamation-circle"/>
          <div class="d-flex flex-column text-center">
            Update Is Available
            <span class="text-sm" @click="updateApp">Click to Update</span>
          </div>
        </a>
      </div>

      <div class="accordion-item" v-if="permissions && permissions.support">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne">
            Support
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
          <div class="accordion-body">
            <router-link @click.native="back" class="route-btn w-100 bg-transparent" :to="{ name : 'user-dashboard' }">
              <font-awesome-icon class="icon" icon="fa-solid fa-chart-simple"/>
              My Dashboard
            </router-link>

            <a @click="createTicket" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Create Ticket
            </a>

            <a @click="searchTicket" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="magnifying-glass"/>
              Ticket Search
            </a>

            <a @click="UserPerformance" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="chart-line"/>
              Statistics
            </a>

          </div>
        </div>
      </div>

      <div class="accordion-item" v-if="permissions && permissions.projectManagement">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo">
            Project Management
          </button>
        </h2>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="openProjectManagement" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="list-check"/>
              Projects
            </a>
            <a @click="viewJobCard" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="eye"/>
              Job Cards
            </a>
            <a @click="navigateToFollowUp" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="pen-to-square"/>
              Make a follow up
            </a>
            <a @click="kycValidation" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clock"/>
              KYC Validation
            </a>

            <a @click="stockDispatch" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="boxes-stacked"/>
              Stock
            </a>
            <a @click="setup" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="list-check"/>
              Setup
            </a>
<!--            <a @click="calendar" class="route-btn w-100 bg-transparent">-->
<!--              <font-awesome-icon class="icon" icon="calendar"/>-->
<!--              Calendar-->
<!--            </a>-->
          </div>
        </div>
      </div>

      <a @click="viewCustomers" class="route-btn border w-100 bg-transparent" v-if="permissions && permissions.customerInfo">
        <font-awesome-icon class="icon" icon="users"/>
        Customers
      </a>

      <a @click="AppMarket" class="route-btn border w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="fa-store"/>
        App Market
      </a>

      <a @click="requestFunds" class="route-btn w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="hand-holding-dollar"/>
        Request Funds
      </a>

      <a @click="requestLeave" class="route-btn w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="fa-solid fa-person-walking-luggage"/>
        Request Leave
      </a>

      <a @click="Projects" class="route-btn border w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="fa-diagram-project"/>
        Development Projects
      </a>


      <div class="accordion-item" v-if="permissions && permissions.sales">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSales" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseSales">
            Sales
          </button>
        </h2>

        <div id="panelsStayOpen-collapseSales" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="openSalesPersonDashboard" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="headset" />
              My Sales Dashboard
            </a>

            <a @click="viewAds" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="headset" />
              View Ads
            </a>

            <a @click="viewLeads" class="route-btn w-100 bg-transparent mt-2">
              <font-awesome-icon class="icon" icon="pencil" />
              View Leads
            </a>
          </div>
        </div>
      </div>




      <div class="accordion-item" v-if="permissions && permissions.staffManagement">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree">
            HR / Staff
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="viewStaff" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              View Staff
            </a>

            <a @click="viewRequestLeave" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-bus"/>
              View Request Leave
            </a>

            <a @click="disciplinary" class="route-btn w-100 bg-transparent" >
              <font-awesome-icon class="icon" icon="fa-solid fa-eye"/>
              Disciplinary
            </a>

            <a @click="viewRoles" class="route-btn w-100 bg-transparent" >
              <font-awesome-icon class="icon" icon="fa-solid fa-pencil"/>
              Roles
            </a>
            <a @click="manageDepartments" class="route-btn w-100 bg-transparent" >
              <font-awesome-icon class="icon" icon="fa-solid fa-users"/>
              Departments
            </a>
          </div>
        </div>
      </div>
<!--v-show="curUser.id === curUser.deptInfo.managerId"-->
      <div class="accordion-item" v-if="permissions && permissions.smartInvoicing">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour">
            Smart Invoice
          </button>
        </h2>
        <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="viewSmartInvoiceSignup" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              Signups
            </a>
          </div>
        </div>
      </div>

      <div class="accordion-item" v-if="permissions && permissions.branchAndLocation">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour">
            Branch Management
          </button>
        </h2>
        <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="openOfficeDashboard" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              Office Dashboard
            </a>
            <a @click="openOfficeManagement" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-building-ngo"/>
              Office Management
            </a>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour">
            Sprints
          </button>
        </h2>
        <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="openSprintManagement" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              Sprint Management
            </a>
          </div>
        </div>
      </div>

      <a @click="settingsPage" class="route-btn border w-100 bg-transparent btn-cont" v-if="permissions && permissions.settings">
        <svg
            class="settings-btn"
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            viewBox="0 -960 960 960"
            width="30"
            fill="#606d77"
        >
          <path class="icons"
                d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 ```html
                  99t99.5 41Zm-2-140Z"
          ></path>
        </svg>
        Settings
      </a>


      <a @click="logOut" class="route-btn border w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="lock"/>
        Logout
      </a>
    </div>
  </div>

</template>
<script>
import axios from "axios";
import {Storage} from "@/services/Storage"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NavigationDrawer",
  data() {
    return {
      userRole: '',
    }
  },
  props: {
    close: {
      type: Function,
      required: true,
    }
  },

  created() {
    this.assign();
    this.getAllRoles();
  },
  computed: {
    ...mapGetters(['getRoles']),
    permissions() {
      return this.$store.getters.getPermissions;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    curUser() {
      let user = this.$store.getters.getCurrentUser;
      if (user) {
        return user;
      }
      return {
        Username: 'Null',
        Type: 'Unknown',
      }
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
    currentUserRole() {
      return this.getRoles.find(role => role.roleName === this.userRole);
    }
  },
  methods: {
    ...mapActions(['getAllRoles']),
    checker() {
      console.log(this.permissions);
    },
    assign() {
      this.userRole = this.curUser.Type;
      console.log('this is the role', this.userRole);
    },
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    async navigateToFollowUp() {
      this.back();
      return this.$router.push({ name: 'FollowUp' });
    },
    someMethod() {
      console.log(this.curUser, "this is our currently logged in user");
      // other logic...
    },
     createTicket(){
      this.back();
      return this.$router.push({ name : `create-ticket` });
    },

    onlineChecker() {
      this.back();
      return this.$router.push({ name: 'online-checker' });
    },

    searchTicket() {
      this.back();
      this.$router.push({ name: 'search-ticket' })
    },
    viewJobCard() {
      this.back();
      this.$router.push({ name: 'viewJobCard' })
    },
    requestEarlyLeave() {
      this.$router.push('/request/early-leave').catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    },


    openProjectManagement(){
      this.back();
      return this.$router.push({ name : `JobCardManagement` });
    },
    stockDispatch(){
      this.back();
      return this.$router.push({ name : `PendingStock` });
    },
    setup(){
      this.back();
      return this.$router.push({ name : `MilestonesPage` });
    },

    kycValidation(){
      this.back();
      return this.$router.push({ name : `PendingEvents` });
    },

    calendar(){
      this.back();
      return this.$router.push({ name : `calendar` });
    },
    viewStaff(){
      this.back();
      return this.$router.push({ name : `users` });
    },
    requestFunds(){
      this.back();
      return this.$router.push({ name : `RequestFunds` });
    }
    ,requestLeave(){
      this.back();
      return this.$router.push({ name : `request-leave` });
    },
    viewRequestLeave(){
      this.back();
      return this.$router.push({ name : `view-leave` });
    },
    disciplinary(){
      this.back();
      return this.$router.push({ name : `ViewDisplinary` });
    },
    viewRoles(){
      this.back();
      return this.$router.push({ name : `roles` });
    },
    manageDepartments(){
      this.back();
      return this.$router.push({ name : `department` });
    },
    fines(){
      this.back();
      return this.$router.push({ name : `fine` });
    },
    viewCustomers(){
      this.back();
      return this.$router.push({ name : `customer-list` });
    },
    // sales(){
    //   this.back();
    //   return this.$router.push({ name : `view-ads` });
    // },
    viewAds() {
      this.back();
      return this.$router.push({ name: "view-ads" });
    },
    openSalesPersonDashboard() {
      this.back();
      return this.$router.push({ name: "salesPersonDashboard" });
    },
    viewLeads() {
      this.back();
      return this.$router.push({ name: "view-leads" });
    },
    settingsPage() {
      this.back();
      return this.$router.push({ name: "settings" });
    },
    logOut() {
      this.back();
      return Swal.fire({
        title: 'Are you sure?',
        text: "You are about to be logged out!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log out!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clearPermissions");
          Storage.setCurrentUser(null);
          this.$router.push({name: "login"})
        }
      })
    },
    viewSmartInvoiceSignup(){
      this.back();
      return this.$router.push({ name : `smart-invoice-product-list` })
    },
    openOfficeManagement(){
      this.back();
      return this.$router.push({ name : `OfficeManagement` })
    },
    openSprintManagement(){
      this.back();
      return this.$router.push({ name : `SprintManagement` })
    },
    openOfficeDashboard(){
      this.back();
      return this.$router.push({ name : `officeDashboard` })
    },
    UserPerformance(){
      this.back();
      return this.$router.push({ name : `performance` })
    },
    AppMarket(){
      this.back();
      return this.$router.push({ name : `appMarket` })
    },
    Projects(){
      this.back();
      return this.$router.push({ name : `projects` })
    },
    OfficeManagement(){
      this.back();
      return this.$router.push({ name : `OfficeManagement` })
    },
    OfficeDashboard(){
      this.back();
      return this.$router.push({ name : `officeDashboard` })
    },
    CustomerEx(){
      this.back();
      return this.$router.push({ name : `Customer-experience` })
    },
    updateApp() {
      this.$store.commit(`setUpdateAvailability`, false);
      return window.location.reload(true);
    },
  }
}
</script>

<style lang="scss" scoped>

a {
  &:hover, &.a-exact-active {
    background: #f1f1f1;
    color: #3F8DCD;

    .icon {
      color: #3F8DCD;
    }
  }
}

.warning {
  border-radius: 50%;
  display: inline-flex; /* Ensures that the container can shrink to fit its content */
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  box-shadow: 0 0 0 9px rgba(217, 193, 98, 0); /* Starting with transparent box-shadow */
  transform: scale(1);
  animation: pulse 2s infinite;
  transform-origin: center center; /* Ensures transform scales from the center */
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Fully opaque at start */
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 19px rgba(217, 193, 98, 0); /* Fully transparent and expanded shadow */
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Return to start */
  }
}

.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #15356E;
          font-weight: 600;
        }
      }

    }
  }
}

.menu {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.icon {
  margin-right: 10px;
  color: #606d77;
  font-size: 1.5rem;
}

.route-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;
}

#footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.not-footer {
  margin-bottom: 100px;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box {
  display: inline-block;
}

.text-white {
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.route-btn{
  cursor: pointer;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.2s ease;
}
.button:hover .tooltip {
  background-color: rgba(255, 255, 255, 0.1);
  visibility: visible;
  opacity: 1;
}
.settings-btn {
  margin-right: 10px;
  display: block;
  transition: transform 0.4s ease-in;
}
.btn-cont:hover .settings-btn {
  transform: rotate(90deg);
}
.settings-btn:active {
  animation: rot 1s linear infinite;
}
@keyframes rot {
  from {
    transform: rotate(-100deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

</style>

