export default {
    name: 'sprints Management',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllSprints: async (context) => {
            return await axios.get(`${synergy4}sprints/all`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getAllProjects: async (context) => {
            return await axios.get(`${synergy4}api/devprojects/getallprojects`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        addTicketNumber: async (context,payload) => {
            const url = `${synergy4}devtasks-association/create`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        addTagToTask: async (context,payload) => {
            const url = `${synergy4}ticket-tags`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        createSprint: async (context,payload) => {
            const url = `${synergy4}sprints/create`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        updateStatus: async (context,payload) => {
            const url = `${synergy4}sprints/update-status`;
            const method = 'put';
            return await context.dispatch('sendRequest',{url,payload,method})
        },
        getTagTypes: async (context) => {
            return await axios.get(`${synergy4}types`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        createTageType: async (context,payload) => {
            const url = `${synergy4}types`;
            return await context.dispatch('sendRequest',{url,payload})
        },
    }
}
