import Vue from 'vue'
import Vuex from 'vuex'
import user_module from "@/store/modules/user_module";
import smart_invoice_module from "@/store/modules/smart-invoice";
import ticket_module from "@/store/modules/ticket_module";
import customer_module from "@/store/modules/customer_module";
import ticket_notes_module from "@/store/modules/ticket_notes_module";
import ticket_tasks_module from "@/store/modules/ticket_tasks_module";
import ticket_document_module from "@/store/modules/ticket_document_module";
import print_module from "@/store/modules/print_module";
import emailModule from "@/store/modules/email-module";
import chatModule from "@/store/modules/chat-module";
import expenseModule from "@/store/modules/expense-module";
import timeModule from "@/store/modules/time-module";
import axios from "axios";
import home_screen_module from "@/store/modules/home_screen_module";
import LeaveRequest from "@/store/modules/LeaveRequest";
import disciplinary from "@/store/modules/disciplinary";
import EncryptDecrypt from "@/store/modules/encrypt-decrypt";
import stock from "@/store/modules/stock";
import Swal from "sweetalert2";
import windowModule from "@/store/modules/ui/window-module";
import VuexPersistence from "vuex-persist";
import {set, get , del} from 'idb-keyval';

const indexedDBStorage={
    getItem : async (key)=>{
        const value = await get(key);
        console.log('Getting from Indexed DB Storage' , {key,value});
        return value;
    },
    setItem : async (key, value)=>{
        console.log('Saving to IndexedDB Storage' , {key,value});
        await set(key, value);
    },
    removeItem : async (key)=>{
        console.log('Removing from IndexedDB Storage' , key);
        await del(key);
    }
}
import Office from "@/store/modules/office";
import SalesManagement from "@/store/modules/salesManagement";
import sprits from "@/store/modules/sprits";
import task from "@/store/modules/task";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: indexedDBStorage,
    asyncStorage : true,
    restoreState : async (key) => {
        try {
            let value = await get(key);
            console.log('Restoring state from IndexedDB:', { key, value });
            if (value) {
                if (typeof value === `string`) {
                    value = JSON.parse(value)
                }
                value.isHydrated = true;
                return value;
            }
            return { isHydrated: true };
        } catch (err) {
            console.error('Error restoring state:', err);
            return {};
        }
    },
    saveState: async (key, value) => {
        try {
            const cleanState = cleanUpState(value);
            // console.log('Saving state to IndexedDB:', { key, value });
            await set(key, cleanState);
        } catch (err) {
            console.error('Error saving state:', err);
        }
    }
})
function cleanUpState(state) {
    return JSON.parse(JSON.stringify(state));
}

export default new Vuex.Store({
    state: {
        loading: false,
        isHydrated : false,
        isUpdateAvailable: false,
        isSynergyLive : true,
        departments: [],
        businessType: [],
    },
    getters: {
        getLoadingState: (state) => state.loading,
        getSynergyAvailability: (state) => state.isSynergyLive,
        isHydrated: (state) => state.isHydrated,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
        getBusinessTypes: (state, payload) => state.businessType,
    },
    mutations: {
        setLoadingState: (state, payload) => state.loading = payload,
        setBusinessTypes: (state, payload) => state.businessType = payload,
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
        setDepartments: (state, payload) => state.departments = payload,
    },
    actions: {
        getDashboardData: (context) => {
            return axios.all([
                context.dispatch('getCurrentUserTicketCount'),
                context.dispatch('getOpenTicketCount'),
                context.dispatch('getTicketsByTab', 1),
                context.dispatch('getAllTicketsGroupedByCustomer'),
            ]);
        },
        getPreLoginData: (context, isRefresh = false) => {
            return axios.all([
                context.dispatch('getAllUsers'),
                context.dispatch('getAllDebtors'),
                context.dispatch('getAllTicketStatuses'),
                context.dispatch('getAllTicketTypes'),
                context.dispatch('getPaymentOptions')
            ]);
        },

        sendRequest(context, {url, payload, headers = {}, method = 'post'}) {
            const maxRetries = 3;
            let currentRetry = 0;
            if (!payload.localid){
                payload.localid = generateUUID();
            }

            const attemptRequest = async () => {
                return await axios[method](url, payload, {
                    headers: headers || {}
                }).then(({data}) => {
                    if (!data.processed) {
                        // Check if the response indicates unprocessed data and if retries are available
                        if (currentRetry < maxRetries) {
                            currentRetry++;
                            console.log(`Attempt ${currentRetry}: Data unprocessed, retrying...`);
                            return attemptRequest(); // Retry the request
                        }
                        // Throw an error if max retries are reached with unprocessed data
                        throw new Error('Data still unprocessed after maximum retries : ' + maxRetries);
                    }
                    payload.localid = null;
                    return data.response; // Return the response if data is processed
                }).catch(err => {
                    if (err.code === 'ECONNABORTED' || err.message.includes('timeout')) {
                        if (currentRetry < maxRetries) {
                            currentRetry++;
                            console.log(`Attempt ${currentRetry}: Retrying...`);
                            return attemptRequest(); // Retry the request
                        }
                    }
                    throw err; // Re-throw the error if it's not a timeout or retries are exhausted
                });
            };
            return attemptRequest()
                .then(response => {
                    payload.localid = null;
                    return response
                })
                .catch(err => errorHandler.tomcatError(err));
        }
    },
    modules: {
        user_module,
        ticket_module,
        customer_module,
        ticket_notes_module,
        ticket_tasks_module,
        ticket_document_module,
        home_screen_module,
        print_module,
        stock,
        emailModule,smart_invoice_module,windowModule,
        chatModule,expenseModule,timeModule,LeaveRequest,disciplinary,Office,SalesManagement,sprits,task
    },

    plugins:[
        vuexLocal.plugin,
    ]
})
