export default {
    name: 'task Management',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getTask: async (context,id) => {
            return await axios.get(`${synergy4}tasks/sprint/${id}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        createTasks: async (context,payload) => {
            const url = `${synergy4}tasks/create`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        updateTask: async (context,payload) => {
            const url = `${synergy4}tasks/update-status`;
            return await context.dispatch('sendRequest',{url,payload})
        },
    }
}
