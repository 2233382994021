import axios from "axios";
import {Storage} from "@/services/Storage";


export default {
    name: 'expense',
    state: {
        expensePaymentOptions: [],
    },
    getters: {
        getExpensePaymentOptions: (state) => state.expensePaymentOptions
    },
    mutations: {
        setExpensePaymentOptions: (state, data) => state.expensePaymentOptions = data
    },
    actions: {
        getPaymentOptions: (context) => {
            return axios.get(ticketApi2 + `payment-types`)
                .then(({data}) => context.commit(`setExpensePaymentOptions`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
        getExpensesByTicket: (context,id) => {
            return axios.get(ticketApi2 + `statistics/getExpensesByTicket/`+id)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        getExpensesByUser: (context,user) => {
            let obj = {
                user,
                processed: true,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(ticketApi2+`statistics/getExpensesByUser`,{
                params: obj
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        requestExpense:async (context,payload) => {
            let user = await Storage.getCurrentUser();
            payload.requestUser = user.Username;
            return axios.post(ticketApi2 + `transaction-handler/user-request`,payload)
                .catch(err => errorHandler.tomcatError(err))
        },
    }
}
