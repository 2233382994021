<template>
  <div v-if="isVisible" class="modal-overlay" >
    <div class="modal-contentt form-container">
      <div class="modal-header" >
      </div>

      <div class="modal-bodyy">
        <slot></slot>
      </div>
<!--      <button type="button" @click="$emit('close')" class="button">-->
<!--        Close-->
<!--      </button>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "PopUpModal",
  props: {
    isVisible: Boolean, // Receive visibility state from parent
  },

}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-contentt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  width: 95%;
  max-width: 95%;
  max-height: 99vh;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.modal-bodyy {
  flex: 1; /* Makes the body take up remaining space */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 1rem;
  width: 100%;
  height: fit-content;
}
.modal-header {
  position: sticky;
  top: 10px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  z-index: 1000;
}
.button {
  color: white;
  background-color: #1d90dd;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
}

.button:hover {
  background-color: darkblue;
}

.button svg {
  display: inline;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.75rem;
  color: white;
}

.button:focus svg {
  animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

</style>