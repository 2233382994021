export default {
    name: 'office Management',
    state: {},
    getters: {
        getManager: (state) => (fieldManagerId) => {
            const fieldManager = state.allUsers;
            const managerFound = fieldManager.find(manager => manager.id === fieldManagerId);
            return managerFound ? managerFound.Username : 'N/A';
        }
    },
    mutations: {},
    actions: {
        getSalesVolume: async (context,payload) => {
            const {from,to} = context.getters.getGlobalDateRange;
            return await axios.get(`${synergy4}office/sales-volume?officeId=${payload}&startDate=${from}&endDate=${to}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getJobcards: async (context,payload) => {
            return await axios.get(`${synergy4}office/GetJobcards?officeId=${payload.officeId}&searchBy=${payload.searchBy}&limit=${payload.limit}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getOffices: async (context) => {
            return await axios.get(`${synergy4}employee/getOffices`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getOverview: async (context) => {
            const {from,to} = context.getters.getGlobalDateRange;
            return await axios.get(`${synergy4}office/office-overview?startDate=${from}&endDate=${to}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getAllBusinessTypes: async (context) => {
            return await axios.get(`${synergy4}Ads/getAllBusinessTypes`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getAvailableSales: async (context,payload) => {
            return await axios.get(`${synergy4}api/sales/available?officeId=${payload.officeId}&dateTime=${payload.dateTime}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getStaff: async (context,payload) => {
            return await axios.get(`${synergy4}office/GetStaffMembersByOffice?officeID=${payload.officeID}&type=${payload.type}&username=${payload.username}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getExpenses: async (context,payload) => {
            const {from,to} = context.getters.getGlobalDateRange;
            return await axios.get(`${synergy4}office/GetExpenses?officeID=${payload}&startDate=${from}&endDate=${to}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        getCustomers: async (context,payload) => {
            return await axios.get(`${synergy4}office/GetCustomersByOffice?officeID=${payload.officeID}&limit=${payload.limit}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        salesTargets: async (context,payload) => {
            return await axios.get(`${synergy4}sales-targets/office/${payload}`)
            .then(({data})=>data)
            .catch((err) => errorHandler.tomcatError(err))
        },
        createOffice: async (context,payload) => {
            const url = `${synergy4}employee/createOffice`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        updateOffice: async (context,payload) => {
            const url = `${synergy4}employee/updateOffice`;
            const method = `put`
            return await context.dispatch('sendRequest',{url,payload,method})
        },
        createTarget: async (context,payload) => {
            const url = `${synergy4}sales-targets/create`;
            return await context.dispatch('sendRequest',{url,payload})
        },
        async getSalesTargets(context) {
            return await axios.get(`${synergy4}sales-targets/`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getTicketTypes(context) {
            return await axios.get(`${synergy4}types`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getTags(context) {
            return await axios.get(`${synergy4}tags`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getSalesTargetByOffice(context, payload) {
            return await axios.get(`${synergy4}sales-targets/office/${payload.officeId}/${payload.selectedYear}`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getSalesTargetByYear(context, year) {
            return await axios.get(`${synergy4}sales-targets/Sales-Target-Per-Year/${year}`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        }
    }
}
