import {Storage as Store} from '@capacitor/storage';
import store from "@/store";

export const CurrentUser = 'current_user';
export const SESSION_ID = 'sessionID';
export const AllUsers = 'all_users';
export const DEBTORS = 'debtors';
export const Ticket_Statuses = 'ticket_statuses';
export const Ticket_Types = 'ticket_types';
export const Customers = 'customers';

export const Storage = {
    setCurrentUser: async (payload) => {
        await Store.set({
            key: CurrentUser, value: JSON.stringify(payload)
        })
    },
    getCurrentUser: async () => {
        let value = store.getters.getCurrentUser;
        if (value) return value;

        value = await Store.get({
            key: CurrentUser
        })
        return JSON.parse(value.value);
    },

    setUsers: async (payload) => {
        await Store.set({
            key: AllUsers, value: JSON.stringify(payload)
        })
    },

    getAllUsers: async () => {
        const value = await Store.get({
            key: AllUsers
        })
        return JSON.parse(value.value);
    },

    setCustomers: async (payload) => {
        await Store.set({
            key: Customers, value: JSON.stringify(payload)
        })
    },
    setDebtors: async (payload) => {
        await Store.set({
            key: DEBTORS, value: JSON.stringify(payload)
        })
    },

    getAllCustomers: async () => {
        const value = await Store.get({
            key: Customers
        })
        return JSON.parse(value.value);
    },

    getAllDebtors: async () => {
        const value = await Store.get({
            key: DEBTORS
        })
        return JSON.parse(value.value);
    },

    setStatuses: async (payload) => {
        await Store.set({
            key: Ticket_Statuses, value: JSON.stringify(payload)
        })
    },

    getAllTicketStatuses: async () => {
        const value = await Store.get({
            key: Ticket_Statuses
        })
        return JSON.parse(value.value);
    },

    setTicketTypes: async (payload) => {
        await Store.set({
            key: Ticket_Types,
            value: JSON.stringify(payload)
        })
    },

    getAllTicketTypes: async () => {
        const value = await Store.get({
            key: Ticket_Types
        })
        return JSON.parse(value.value);
    },
    setSessionID: async (payload) => {
        await Store.set({
            key: SESSION_ID,
            value: JSON.stringify(payload)
        })
    },

    getSessionID: async () => {
        const value = await Store.get({ key: SESSION_ID })
        return JSON.parse(value.value);
    },
}
