import axios from "axios";

function isImageURL(url) {
    return new Promise(function (resolve, reject) {
        let img = new Image();
        img.onload = function () {
            resolve(true);
        };
        img.onerror = function () {
            resolve(false);
        };
        img.src = url;
    });
}

export default {
    name: 'Sales Management',
    state: {
        DB: 'burntofi_chrilan',
        api: "945ac88d-e88a-11ed-91dd-00163e8d4996",
        quoteId: null,
        debtor: null,
        stock: [],
        stockSetting: [],
        stockItem: null,
        stockCache: new Map(),
        stockImages: [],
        currentCategory: '',
        subCategory: '',
        sub2Category: '',
        categories: [],
        customer: '',
        currentSaleLines: [],
        limit : 30,
        currentSale: 0,
        searchCategory : {
            category: ``,
            subCategory: ``,
            sub2Category: ``
        }
    },
    getters: {
        getApiKey: (state) => state.api,
        getDebtor: (state) => state.debtor,
        getCustomer: (state) => state.customer,
        getCategories: (state) => state.categories,
        getSearchCategories:(state) =>state.searchCategory,
        getSaleLines: (state) => state.currentSaleLines,
        getStockProducts: (state) => (limit) => {
            let items = state.stock;
            let products = [];
            for (let i = 0; i < items.length; i++){
                let item = products.find(p=>p.PLU===items[i].PLU);
                if (!item){
                    items[i].index = i+1;
                    if (limit){
                        if (items[i].index < limit){
                            products.push(items[i]);
                        }
                    }else{
                        products.push(items[i]);
                    }
                }
            }
            return products;
        },
        getLimit:(state) => state.limit,
        getCurrentSale: (state) => state.currentSale,
        getCurrentCategory: (state) => state.currentCategory,
        getSubCategory: (state) => state.subCategory,
        getSubCategory2: (state) => state.sub2Category,
        getStock: (state) => state.stock,
        getCurrentSaleLine: (state) => state.currentSaleLines,
        getQuoteId: (state) => state.quoteId,
        getTotalsaleLines: (state) => {
            return state.currentSaleLines.reduce((total, saleline) => {
                return total + (saleline.Qty * saleline.sellingPrice);
            }, 0);
        },
        getFastLine: (context, query) => {
            context.commit(`setSearchCategories`, {
                category: ``,
                subCategory: ``,
                sub2Category: ``
            })
            return Storage.getFastLine().then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
    },
    mutations: {
        setLimit:(state,limit) =>state.limit = limit,
        setCustomer: (state, data) => {
            state.customer = data;
        },
        setDebtor: (state, data) => {
            state.debtor = data;
        },setSearch: (state, payload) => {
            console.log('payload', payload);
            if (Array.isArray(payload)) {
                state.stock = payload.sort((a, b) => {
                    if (a.Description < b.Description) return -1;
                    if (a.Description > b.Description) return 1;

                    // If Descriptions are the same, then sort by PLU
                    if (a.PLU < b.PLU) return -1;
                    if (a.PLU > b.PLU) return 1;

                    return 0;
                });
            } else {
                console.error('Expected payload to be an array, but got:', payload);
                state.stock = [];
            }
        },
        setCategories: async (state, payload) => {
            state.categories = payload
        },
        setSearchCategories:(state , payload) =>state.searchCategory = payload,
        setQuoteId:(state , payload) => {
            console.log(`payload`, payload)
            state.quoteId = payload
        },
        setCurrentCategory: (state, payload) => state.currentCategory = payload,
        setSubCategory: (state, payload) => state.subCategory = payload,
        setSubCategory2: (state, payload) => state.sub2Category = payload,
        setStockImages: (state, payload) => {
            let images = [];
            for (let i = 0; i < payload.length; i++) {
                let itemImage = payload[i];
                let isValid = isImageURL(itemImage.Image)
                if (isValid) {
                    images.push(itemImage)
                }
            }
            state.stockImages = images
        },
        setStock: (state, stock) => state.stock = stock,
        addToCart: (state, stock) => {
            console.log('stock',stock)
            console.log('stock',stock.Plu)
            const existingItemIndex = state.currentSaleLines.findIndex(
                item => item.Plu === stock.Plu
            );
            console.log(`existingItemIndex`,existingItemIndex)
            if (existingItemIndex !== -1) {
                return state.currentSaleLines[existingItemIndex].Qty += 1;
            } else {
                const newItem = {
                    ...stock,
                    Qty: 1,
                };
                state.currentSaleLines.push(newItem);
            }
        },
        decrementItem(state, stock) {
            const existingItemIndex = state.currentSaleLines.findIndex(
                item => item.Plu === stock.Plu
            );

            if (existingItemIndex !== -1) {
                if (state.currentSaleLines[existingItemIndex].Qty === 1) {
                    return this.commit('removeItem', existingItemIndex);
                } else {
                    return state.currentSaleLines[existingItemIndex].Qty -= 1;
                }
            } else {
                console.log('Item not found in saleline.');
            }
        },
        removeItem(state, index) {
            console.log(index)
            if (index >= 0 && index < state.currentSaleLines.length) {
                state.currentSaleLines.splice(index, 1);
            } else {
                console.error('Invalid index:', index);
            }
        },
        stockByPLU: async (state, payload) => {
            if (payload) {
                payload.sort((a, b) => a.Description.localeCompare(b.Description))

                state.stock = payload.filter(x => x.FastLine === 1)
                    .sort((a, b) => a.Description.localeCompare(b.Description));
                state.stockCache = payload.reduce((mapAcc, obj) => {
                    mapAcc.set(obj.PLU.toLowerCase(), obj);
                    return mapAcc;
                }, new Map())

            } else {
                state.stock = state.stock.sort((a, b) => a.Description.localeCompare(b.Description))
            }
        },
        setStockSetting: async (state, payload) => {
            if (payload) {
                state.stockSetting = payload;
            }
        },
    },
    actions: {
        printQuoteById(context,id) {
            let url = `${printApi}Quotes/${context.state.DB}/${id}`;
            window.open(url,`_blank`);
        },
        getAvailableSalesPersons(context,payload) {
            return axios.get(`${synergy4}api/sales/available?officeId=${payload.officeId}&dateTime=${payload.dateTime}`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        createLead(context,payload) {
            const url = `${synergy4}api/sales/createSalesMeetingTicket`
            return context.dispatch('sendRequest',{url,payload})
        },
        addTagToTicket(context,payload) {
            const url = `${synergy4}ticket-tags`
            return context.dispatch('sendRequest',{url,payload})
        },
        fetchSalesMeetings(context,payload) {
            const {from,to} = context.getters.getGlobalDateRange;
            return axios.get(`${synergy4}api/sales/SalesMeetings?startDate=${from}&endDate=${to}&userName=${payload.userName}&status=${payload.status}`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        paymentTypes(context) {
            return axios.get(`${ticketApi2}payment-types`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        fetchRequests(context,payload) {
            return axios.get(`${synergy4}api/sales/${payload}/requests/count`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        fetchTagById(context,payload) {
            return axios.get(`${synergy4}ticket-tags/getTicketTagsByTicketNumber?ticketNumber=${payload}`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        fetchProposal(context,payload) {
            return axios.get(`${synergy4}api/sales/proposals/byTicket?ticketNumber=${payload}`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        fetchProposalCustomer(context,payload) {
            return axios.post(`${Franchise_OnlineServer}debtor/${context.state.DB}/search`,payload)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        createDebtor(context,payload) {
            return axios.post(`${OnlineSalesServer}debtor/${context.state.DB}`,payload)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        async editSalesTarget(context,payload) {
            const url = `${synergy4}sales-targets/updateSalesTarget/${payload.year}`;
            const method = 'put';
            return await context.dispatch('sendRequest',{url,payload,method})
        },
        updateProposalStatus: async (context,payload) => {
            return await axios.post(`${synergy4}api/sales/update-status?saleNumber=${payload.saleNumber}&status=${payload.status}`)
                .catch(error => errorHandler.tomcatError(error));
        },
        onSiteLogs: async (context,payload) => {
            return await axios.get(`${synergy4}employee/on-site-logs?ticketId=${payload}`)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        userRequest: (context,payload) => {
            const url = `${ticketApi2}transaction-handler/user-request`;
            return axios.post(url, payload)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        requestToLeaveSite: (context,payload) => {
            const url = `${synergy4}employee/requestToLeaveSite/${payload}`;
            return axios.post(url, payload)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        async receiveIntegratedPayment(context, payload) {
            const url = `${backOfficeTomcatApi}popValidation/${context.state.DB}/receiveIntegratedPayment`;
            return await axios.post(url, payload)
                .catch(error => errorHandler.tomcatError(error));
        },
        async createTransaction(context, payload) {
            const url = `${KazangPaymentGatewayV2}GateWay/CreateTransaction`;
            payload.API_KEY = context.getters.getApiKey;
            return await context.dispatch('sendRequest',{url, payload,})
                .then((data)=> data)
        },
        async popValidation(context, payload) {
            const url = `${backOfficeTomcatApi}popValidation/${context.state.DB}`;
            return await axios.post(url, payload)
        },
        createOnSiteLog: (context,payload) => {
            const url = `${synergy4}employee/createOnSiteLog`;
            return axios.post(url, payload)
                .then(({data})=>data)
                .catch(error => errorHandler.tomcatError(error));
        },
        addSalesNumberTicket: (context,data) => {
            console.log(`data`, data)
            const url = `${synergy4}api/sales/Add-SalesNumber-Ticket`;
            const id = context.getters.getQuoteId;
            console.log(id)
            const  payload = {
                saleNumber: data.SaleNum,
                ticketNumber: id.id,
                status: "PENDING"
            }
            console.log(`payload`,payload)
            return context.dispatch('sendRequest',{url,payload})
        },
        saveQuote: async (context) => {
            let saleLines = context.getters.getSaleLines;
            for (let i = 0; i < saleLines.length; i++) {
                let line = saleLines[i];
                saleLines[i].Line = (Number(line.Qty) * Number(line.Unit)) - Number(line.Discount);
            }
            const customer = context.getters.getQuoteId;
            const debtor = context.getters.getDebtor;
            console.log(`customer`,customer)
            console.log(`customer`,debtor)
            let payload = {
                SaleNum: context.getters.getCurrentSale,
                Name: debtor.Name,
                User: context.getters.getCurrentUser.Username,
                PC: context.getters.getCurrentUser.Username,
                items: saleLines,
                Customer: debtor.Id,
                discount: `0`,
            }
            console.log(payload)

            const url = `${OnlineSalesServer}tables/${context.state.DB}`;
            return await axios.post(url, payload)
                .then(async ({data})=> {
                    await context.dispatch('addSalesNumberTicket', data);
                    context.state.currentSaleLines = [];
                    context.state.debtor = null;
                })
                .catch(error => errorHandler.tomcatError(error));
        },
        searchStock: (context, query) => {
            console.log(`query`,query)
            let search = context.getters.getSearchCategories;
            query.category = search.category;
            query.subCategory = search.subCategory;
            query.sub2Category = search.sub2Category;

            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return context.state.currentSaleLines
                .filter(s =>
                    s.Description.toLowerCase().includes(query.description.toLowerCase()) ||
                    s.PLU.toLowerCase().includes(query.description.toLowerCase()) ||
                    s.barcode.toLowerCase().includes(query.description.toLowerCase())
                )
                .sort((a, b) => {
                    if (a.Description.toLowerCase() < b.Description.toLowerCase()) {
                        return -1; // a comes first
                    }
                    if (a.Description.toLowerCase() > b.Description.toLowerCase()) {
                        return 1; // b comes first
                    }
                    return 0; // they are equal
                });

        },
        getFastLine: (context, query) => {
            context.commit(`setSearchCategories`, {
                category: ``,
                subCategory: ``,
                sub2Category: ``
            })
            const falineLine = () => {
                let stock = context.state.stock ?? [];
                return stock.filter((sc) => sc.FastLine === 1);
            }
            return context.commit('setSearch', falineLine())
        },
        getCategories: async (context) => {
            context.commit('setCategories')
            return axios.get(serverApi + `category/${context.state.DB}/getAsTree`)
                .then(({data}) => {
                    data = data.filter(category => category.name.trim() !== '')
                        .sort((a, b) => a.name.localeCompare(b.name))
                    context.commit('setCategories', data)
                })
        },
        searchDescription: (context, query) => {
            let search = context.getters.getSearchCategories;
            query.category = search.category;
            query.subCategory = search.subCategory;
            query.sub2Category = search.sub2Category;

            context.commit('setCurrentCategory', query.category);
            context.commit('setSubCategory', query.subCategory);
            context.commit('setSubCategory2', query.sub2Category);

            if (!query.description.trim()) {
                context.commit('setSearch', context.state.stock);
                return;
            }

            const filteredResults = context.state.stock
                .filter((s) => {
                    const description = s.Description ? s.Description.toLowerCase() : '';
                    const plu = s.PLU ? s.PLU.toLowerCase() : '';
                    const barcode = s.barcode ? s.barcode.toLowerCase() : '';
                    return description.includes(query.description.toLowerCase()) ||
                        plu.includes(query.description.toLowerCase()) ||
                        barcode.includes(query.description.toLowerCase());
                })
                .sort((a, b) => {
                    const aDescription = a.Description.toLowerCase();
                    const bDescription = b.Description.toLowerCase();
                    if (aDescription < bDescription) return -1;
                    if (aDescription > bDescription) return 1;
                    return 0;
                });

            context.commit('setSearch', filteredResults);
        },
        getAllStock: async (context) => {
            let url = serverApi + `stock/${context.state.DB}`;
            return axios.get(url, {params: {pc: `DESKTOP-DELTIIC`,user : `DEMO`}})
                .then(async ({data}) => {
                    console.log(data);
                    const stockSettings = data.map(s => {
                        let set = s.StockSetting
                        if (set) {
                            return {
                                index: s.id,
                                plu: s.PLU,
                                cookingLink: set.CookingLink,
                                mtv: set.mtv,
                                askForPrice: set.askForPrice,
                                askForDescription: set.askForDescription,
                                askForBoth: set.askForBoth,
                                NonStock: set.NonStock,
                                tourism: set.tourism,
                                getQtyFromPrice: set.getQtyFromPrice,
                                password_protected: set.password_protected
                            }
                        } else {
                            return {
                                plu: s.PLU,
                                mtv: 0.00,
                                tourism: false,
                                NonStock: false,
                                cookingLink: false,
                                askForPrice: false,
                                askForDescription: false,
                                askForBoth: false,
                            }
                        }
                    })
                    const stock = data.map(s => {
                        delete s.CreatedDate
                        delete s.Image
                        delete s.Price1
                        delete s.Price2
                        delete s.Price3
                        delete s.Price4
                        delete s.Price5
                        delete s.args
                        delete s.where_between
                        delete s.StockSetting
                        return s;
                    });
                    await context.commit('stockByPLU', stock);
                    await context.commit('setStockSetting', stockSettings);
                }).catch((err) => errorHandler.tomcatError(err))
        },
    }
}
